/*
 * Main navigation logic
 */

// Variables
let menuItemHasChildren = document.querySelectorAll('.menu-item-has-children');
let isOpen = false;

// Sub navigation menu toggle logic and handle outside click to close
const menuWithChildren = document.querySelectorAll('.menu-item-has-children');
menuWithChildren.forEach(menu => {
  let handleMenu = menuHandler();
  menu.addEventListener('click', handleMenu.bind(null, menu));
});

function menuHandler() {
  let isOpen = false;
  return (menu, event) => {
    isOpen = !isOpen;
    const subnav = menu.querySelector('.sub-menu');
    const menuWithChildrenLink = menu.firstChild;
      subnav.classList.toggle('active');
      menuWithChildrenLink.classList.toggle('rotate');
  }
}

document.addEventListener('click', (e) => {
  menuItemHasChildren.forEach(menu => {
    if (!e.target.parentElement.classList.contains('menu-item-has-children')) {
      const subnav = menu.querySelector('.sub-menu');
      const menuWithChildrenLink = menu.firstChild;
      subnav.classList.toggle('active');
      menuWithChildrenLink.classList.toggle('rotate');
      if (subnav.classList.contains('active')) {
        subnav.classList.toggle('active');
        menuWithChildrenLink.classList.toggle('rotate');
      }
    }
  })
});

// Hamburger menu toggle logic
const toggleMenu = () => {
  const toggleButton = document.querySelector('.ku-header__hamburger');
  const header = document.querySelector('.ku-header');
  const headerNav = document.querySelector('.ku-header__nav');
  const body = document.body;
  const headerLogoImg = document.querySelector('.ku-header__logo img');
  const headerLogo = document.querySelector('.ku-header__logo');

  if (toggleButton) {
    toggleButton.addEventListener('click', function () {
      toggleButton.classList.toggle('active');
      headerNav.classList.toggle('active');
      body.classList.toggle('ku-header__nav--active');
      headerLogoImg.classList.toggle('active');
      headerLogo.classList.toggle('active');
      header.classList.toggle('active');
    })
  }

}
toggleMenu()